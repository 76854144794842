<template>
  <div class="parallax-home-page">
    <HeaderMultiPageRoute class="parallax-header">
      <!--<img slot="logo" src="../assets/img/logo/YOUNOUS-white.png" /> -->
    </HeaderMultiPageRoute>
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src1 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Sports</h2>
              <div class="mt--30 rf_btn"  @click="linkClicked(1)"
                >View</div
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>

    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src2 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Fashion</h2>
              <div class="mt--30 rf_btn"  @click="linkClicked(3)"
                >View</div
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
 
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src3 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Prints for sale</h2>
              <p>
                Available SOON
              </p>
              <div class="mt--30 rf_btn" @click="linkClicked(4)"
                >View</div
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
   
    <div
      class="slider-area paralx-slider parallax fullscreen d-flex align-center rn-bg-position-fixed"
      :style="{ backgroundImage: 'url(' + src4 + ')' }"
    >
      <v-container>
        <v-row class="row">
          <v-col lg="12">
            <div class="text-center content">
              <h2>Products</h2>
              <div class="mt--30 rf_btn" @click="linkClicked(2)" 
                >View</div
              >
            </div>
          </v-col>
        </v-row>
      </v-container>
    </div>
  

    <Footer />
  </div>
</template>

<script>
  import HeaderMultiPageRoute from "../components/header/HeaderMultiPageRoute";
  import Footer from "../components/footer/Footer";
  import router from '../router/router';
  
  export default {
    components: {
      HeaderMultiPageRoute,
      Footer,
    },
    data() {
      return {
        src1: require("../assets/img/bg/sports.jpg"),
        src2: require("../assets/img/bg/fashion.jpg"),
        src4: require("../assets/img/bg/prints.jpg"),
        src3: require("../assets/img/bg/products.jpg"),
      };
    },
     methods: {
      linkClicked(id) {
        console.log(id);
        localStorage.setItem('currentPortfolio', id)
        router.push({ name: 'Portfolio' });
      }
     }
  };
</script>

<style lang="scss" scoped>
  .parallax-home-page .logo {
    img {
      @media only screen and (max-width: 575px) {
        max-width: 80%;
      }
    }
  }
</style>
