<template>
  <!-- Footer -->
  <footer class="-rn-footer" id="footer">
    <div class="rn-footer-wrapper footer">
      <v-container fluid>
        <v-row>
          <v-col lg="12">
            <div class="footer-top">
              <div class="content">
                <span>Starting a project!</span>
                <h4>Contact Us</h4>
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
      <div class="footer-wrapper ptb--100">
        <v-container fluid>
          <v-row>
            <!-- Start Single Wedget -->
            <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Present Address</h3>
                <div class="content">
                  <p>
                    Rabat, Morocco
                  </p>
                </div>
              </div>
            </v-col>
            <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Contact</h3>
                <div class="content">
                  <div class="address">
                    <p>
                      <a href="mailto:hamza.younousyh@gmail.com">hamza.younousyh@gmail.com</a>
                    </p>
                    <p><a href="tel:+212 624-71-0599">+212 624-71-0599</a></p>
                  </div>
                </div>
              </div>
            </v-col>
            <v-col lg="4" md="4" sm="6" cols="12">
              <div class="footer-widget">
                <h3 class="ft-title">Quick Link</h3>
                <div class="content">
                  <ul class="quick-link">
                    <li v-for="(link, i) in quickLink" :key="i">
                      <router-link :to="link.RouteName">{{
                        link.name
                      }}</router-link>
                    </li>
                  </ul>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-container>
      </div>
    </div>

    <FooterTwo />
  </footer>
  <!--// Footer -->
</template>

<script>
  import FooterTwo from "./FooterTwo";
  export default {
    components: {
      FooterTwo,
    },
    data() {
      return {
        quickLink: [
          {
            name: "Portfolio",
            RouteName: "/portfolio",
          },
          {
            name: "Home",
            RouteName: "/",
          },
          {
            name: "About Us",
            RouteName: "/about-us",
          },
        ],
      };
    },
  };
</script>

<style lang="scss" scoped></style>
